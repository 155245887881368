module services {
    export module master {
        export class calendarPeriodService implements interfaces.master.ICalendarPeriodService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }
            
            getDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "CalendarPeriod/GetDropdownList", {
                    ownerEntityId: '@ownerEntityId',
                    calendarTypeId: '@calendarTypeId',
                    startDate: '@startDate'
                });
            }

            get(capId: number): ng.resource.IResourceClass<interfaces.master.ICalendarPeriod> {

                return this.$resource<interfaces.master.ICalendarPeriod>(this.ENV.DSP_URL + "CalendarPeriod/Get", {
                    capId: capId
                }, {
                        get: {
                            method: 'GET',
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            isArray: false
                        }
                    });
            }
        }
    }
    angular.module("app").service("calendarPeriodService", services.master.calendarPeriodService);
}